import React, { useState, useEffect, Fragment } from "react"
import Arrow from "../images/arrow-right.svg"
import { useStaticQuery, graphql } from "gatsby"

const Footer = ({ options = [], userInputEmail, home, setMadLibsWords }) => {
  const [buttons, setButtons] = useState(null)

  const data = useStaticQuery(graphql`
    {
      contact: contentfulGeneral {
        email
        instagramHandle
      }
    }
  `)

  const { contact } = data

  const { email, instagramHandle } = contact

  const setMadLibs = e => {
    e.preventDefault()
    const madLib = options.find(
      option =>
        option.madlibTitle.toLowerCase() ===
        e.currentTarget.innerText.toLowerCase()
    )
    setMadLibsWords(madLib)
  }

  useEffect(() => {
    if (options.length) {
      setButtons(
        options.map(option => (
          <button
            className="mad-lib-option"
            key={option.id}
            onClick={setMadLibs}
          >
            {option.madlibTitle}
          </button>
        ))
      )
    }
  }, [options])

  return (
    <Fragment>
      {home ? (
        <Fragment>
          <h2 className="mad-lib-title">Choose your tone: </h2>
          <footer className="site-footer mad-libs-buttons">{buttons}</footer>
          {userInputEmail.ready && (
            <a
              href={`mailto:${email}?subject=${"My Mad Lib"}&body=${
                userInputEmail.body
              }`}
              className="send-madlibs"
            >
              Send me your version <img src={Arrow} alt={"Email Arrow"} />
            </a>
          )}
        </Fragment>
      ) : (
        <footer className="site-footer work-footer">
          <div className="email">
            <a
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Email
            </a>
          </div>
          <div className="linkedIn">
            <a
              href={`https://www.linkedin.com/in/evelyn-crowley-55748024/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              in
            </a>
          </div>
          <div className="smile">:)</div>
        </footer>
      )}
    </Fragment>
  )
}

export default Footer
